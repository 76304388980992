import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import RateCard from '@/models/RateCard'

@Model()
export default class Rate extends ApplicationRecord {
  static jsonapiType = 'rates'

  @Attr() amount!: number
  @Attr() communicatedAmount!: number
  @Attr() requirementValue!: number
  @Attr() requirementOperator!: string

  @BelongsTo() rateCard!: RateCard
}
