import { Model, Attr, HasMany } from 'spraypaint'
import { Notification } from 'element-ui'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Sku } from '@/models/index'
import request from '@/utils/request'

@Model()
export default class GiftCard extends ApplicationRecord {
  static jsonapiType = 'gift_cards'

  @Attr() id!: string
  @Attr() name!: string
  @Attr() code!: string
  @Attr() createdAt!: Date
  @Attr() updatedAt!: Date
  @Attr() baseline!: string
  @Attr() description!: string
  @Attr() howToUseOnline!: string
  @Attr() howToUseOffline!: string
  @Attr() termOfUse!: string
  @Attr() websiteUrl!: string
  @Attr() countryAlpha2!: string
  @Attr() barcodeSymbology!: number
  @Attr() validityPeriod!: number
  @Attr() checkBalanceUrl!: string
  @Attr() domUsable!: boolean
  @Attr() onlineUsable!: boolean
  @Attr() offlineUsable!: boolean
  @Attr() btocAvailable!: boolean
  @Attr() splittableOnline!: boolean
  @Attr() splittableOffline!: boolean
  @Attr() cumulativeOnline!: boolean
  @Attr() cumulativeOnlineLimit!: number
  @Attr() cumulativeOffline!: boolean
  @Attr() cumulativeOfflineLimit!: number
  @Attr() cumulativeWithVoucher!: boolean
  @Attr() codePin!: boolean
  @Attr() barcodeByConcatenation!: boolean
  @Attr() passkitEnabled!: boolean
  @Attr() passkitTemplateName!: string
  @Attr() googleWalletEnabled!: boolean
  @Attr() centsEnabled!: boolean

  @Attr() logo!: string
  @Attr() logoSquare!: string
  @Attr() logoPrimaryColor!: string
  @Attr({ persist: false }) logoBlob!: any
  @Attr({ persist: false }) logoSquareBlob!: any

  @HasMany() skus!: Sku[]

  public async restockBgoCards() {
    const params = {
      data: {
        id: this.id,
        type: 'gift_cards'
      }
    }

    try {
      const { data } = await request.put(`/admin/gift_cards/${this.id}/restock_bgo_cards`, params)

      Notification({
        title: 'Restock launched',
        message: 'Restock has been launched',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }
}
