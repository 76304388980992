import { Model, Attr, BelongsTo, HasMany } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Caretaker, GiftCard } from '@/models/index'

@Model()
export default class BurnProgram extends ApplicationRecord {
  static jsonapiType = 'burn_programs'

  @Attr() id!: string
  @Attr() name!: string
  @Attr() description!: string
  @Attr() createdAt!: string
  @Attr() logo!: string

  @BelongsTo() caretaker!: Caretaker
  @HasMany() giftCards!: GiftCard[]
}
