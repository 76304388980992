import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Order, Balance } from '@/models/index'
import request from '@/utils/request'

@Model()
export default class BalanceMove extends ApplicationRecord {
  static jsonapiType = 'balance_moves'

  @Attr() id!: string
  @Attr() createdAt!: Date
  @Attr() amount!: number
  @Attr() dueAt!: string
  @Attr() creditedAt!: string
  @Attr() reason!: string
  @Attr() sourceType!: string
  @Attr() sourceId!: string

  @BelongsTo() order!: Order
  @BelongsTo() balance!: Balance

  public async credit() {
    const params = {
      data: {
        id: this.id,
        type: 'balance_orders'
      }
    }
    await request.put(`/admin/${BalanceMove.jsonapiType}/${this.id}/credit`, params)
  }
}
