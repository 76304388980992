import { Model, Attr } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'

@Model()
export default class Manageable extends ApplicationRecord {
  static jsonapiType = 'manageables'

  @Attr() id!: string
  @Attr() name!: string
}
