import { Notification } from 'element-ui'
import { Model, Attr, BelongsTo, HasOne, HasMany } from 'spraypaint'

import { BankTransfer, CardDistribution, Delivery, Invoice, Buyer, Wholesaler, GiftCard, Seller } from '@/models/index'
import ApplicationRecord from '@/models/ApplicationRecord'
import request from '@/utils/request'

@Model()
export default class Order extends ApplicationRecord {
  static jsonapiType = 'orders'

  @Attr() id!: string
  @Attr() discount!: number
  @Attr() discountWithoutVat!: number
  @Attr() amount!: number
  @Attr() amountCents!: number
  @Attr() discountedAmount!: number
  @Attr() discountedAmountCents!: number
  @Attr() discountedAmountWithoutVat!: number
  @Attr() payableAmount!: number
  @Attr() chargeableAmount!: number
  @Attr() number!: string
  @Attr() customNumber!: string
  @Attr() status!: string
  @Attr() paymentStatus!: string
  @Attr() deliveryStatus!: string
  @Attr() retrievalMode!: string
  @Attr() deliveryMode!: string
  @Attr() balanceEarned!: number
  @Attr() balanceBurned!: number
  @Attr() createdAt!: string
  @Attr() isBulk!: boolean

  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() buyer!: Buyer
  @BelongsTo() wholesaler!: Wholesaler
  @BelongsTo() order!: Order
  @BelongsTo() seller!: Seller

  @HasOne() standardInvoice!: Invoice
  @HasOne() commissionInvoice!: Invoice

  @HasMany() invoices!: Invoice[]
  @HasMany() orders!: Order[]
  @HasMany() cardDistributions!: CardDistribution[]
  @HasMany() deliveries!: Delivery[]

  public static async deliverAll() {
    try {
      await request.get('/admin/orders/deliver_all')

      Notification({
        title: 'Delivery launched',
        message: 'Delivery has been launched',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public async deliver() {
    const params = {
      data: {
        id: this.id,
        type: 'orders'
      }
    }

    try {
      const { data } = await request.put(`/admin/orders/${this.id}/deliver`, params)
      this.status = data.attributes.status
      this.deliveryStatus = data.attributes.deliveryStatus

      Notification({
        title: 'Delivery launched',
        message: 'Delivery has been launched',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public async process() {
    const params = {
      data: {
        id: this.id,
        type: 'orders'
      }
    }

    try {
      const { data } = await request.put(`/admin/orders/${this.id}/to_process`, params)
      this.status = data.attributes.status

      Notification({
        title: 'Order in process',
        message: 'Order is being processed',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public async detachBulk() {
    const params = {
      data: {
        id: this.id,
        type: 'orders'
      }
    }

    try {
      await request.put(`/admin/orders/${this.id}/detach`, params)

      Notification({
        title: 'Order detached',
        message: 'Order is being detach',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      Notification({
        title: 'Order error',
        message: 'Unable to detach the order',
        type: 'error',
        duration: 2000
      })
    }
  }

  public async cancelDelivery() {
    const params = {
      data: {
        id: this.id,
        type: 'orders'
      }
    }

    try {
      const { data } = await request.put(`/admin/orders/${this.id}/cancel_delivery`, params)
      this.deliveryStatus = data.attributes.deliveryStatus

      Notification({
        title: 'Delivery cancelled',
        message: 'Delivery has been cancelled',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public async cancelFulfill() {
    const params = {
      data: {
        id: this.id,
        type: 'orders'
      }
    }

    try {
      const { data } = await request.put(`/admin/orders/${this.id}/cancel_fulfill`, params)
      this.status = data.attributes.status

      Notification({
        title: 'Order rollback',
        message: 'Order has been rollback to process',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public async cancelProcess() {
    const params = {
      data: {
        id: this.id,
        type: 'orders'
      }
    }

    try {
      const { data } = await request.put(`/admin/orders/${this.id}/cancel_process`, params)
      this.status = data.attributes.status

      Notification({
        title: 'Order rollback',
        message: 'Order has been rollback to waiting',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public async fulfill() {
    const params = {
      data: {
        id: this.id,
        type: 'orders'
      }
    }

    try {
      const { data } = await request.put(`/admin/orders/${this.id}/fulfill`, params)
      this.status = data.attributes.status

      Notification({
        title: 'Order fulfilled',
        message: 'Order has been fulfilled',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public async suspend() {
    const params = {
      data: {
        id: this.id,
        type: 'orders'
      }
    }

    try {
      const { data } = await request.put(`/admin/orders/${this.id}/suspend`, params)
      this.status = data.attributes.status

      Notification({
        title: 'Order suspended',
        message: 'Order has been suspended',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public async resume() {
    const params = {
      data: {
        id: this.id,
        type: 'orders'
      }
    }

    try {
      const { data } = await request.put(`/admin/orders/${this.id}/resume`, params)
      this.status = data.attributes.status

      Notification({
        title: 'Order resumed',
        message: 'Order has been resumed',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public async createCards() {
    const params = {
      data: {
        id: this.id,
        type: 'orders'
      }
    }

    try {
      await request.put(`/admin/orders/${this.id}/create_cards`, params)

      Notification({
        title: 'Creating cards ...',
        message: 'Cards are being created',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public async resetDeliveries() {
    const params = {
      data: {
        id: this.id,
        type: 'orders'
      }
    }

    try {
      await request.put(`/admin/orders/${this.id}/reset_deliveries`, params)

      Notification({
        title: 'Reset Deliveries ...',
        message: 'Deliveries has been reset',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public isPaid() {
    return this.paymentStatus === 'paid'
  }

  public isDelivered() {
    return this.deliveryStatus === 'delivered'
  }

  public isNotDelivered() {
    return this.deliveryStatus === 'undelivered' || this.deliveryStatus === 'in_delivery'
  }

  public isInDelivery() {
    return this.deliveryStatus === 'in_delivery'
  }

  public isFulfilled() {
    return this.status === 'fulfilled'
  }

  public isWaiting() {
    return this.status === 'waiting'
  }

  public isProcessing() {
    return this.status === 'processing'
  }

  public isSuspended() {
    return this.status === 'suspended'
  }

  public isApiRetrieval() {
    return this.retrievalMode === 'api_retrieval'
  }

  public canSuspend() {
    return  this.isApiRetrieval() && this.isProcessing()
  }

  public canResume() {
    return this.isApiRetrieval() && this.isSuspended()
  }

  public bankTransferSum(): number {
    if (!this.invoices[0] || this.invoices[0].bankTransfers.length === 0) return 0
    return this.invoices[0].bankTransfers.reduce((acc: number, current: BankTransfer): number => {
      return acc + current.amount
    }, 0)
  }

  public isPartiallyPaid() {
    const bankTransferSum = this.bankTransferSum()
    return !this.isPaid() && bankTransferSum > 0 && bankTransferSum < this.payableAmount
  }

  public payableAmountleft() {
    return (this.payableAmount - this.bankTransferSum()).toFixed(2)
  }
}
