import { Model, Attr, HasOne } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Location } from '@/models/index'

@Model()
export default class Buyer extends ApplicationRecord {
  static jsonapiType = 'buyers'

  @Attr() id!: string
  @Attr() code!: string
  @Attr() name!: string
  @Attr() logo!: string
  @Attr() siren!: string
  @Attr() rcs!: string
  @Attr() intraVatNumber!: string
  @Attr() commissionNumberFormat!: string

  @Attr({ persist: false }) logoBlob!: any
  @HasOne() location!: Location
}
