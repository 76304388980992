import { Model, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Category, GiftCard } from '@/models/index'

@Model()
export default class GiftCardCategory extends ApplicationRecord {
  static jsonapiType = 'gift_card_categories'

  @BelongsTo() category!: Category
  @BelongsTo() giftCard!: GiftCard
}
