import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'

import { Order } from '@/models/index'

@Model()
export default class Delivery extends ApplicationRecord {
  static jsonapiType = 'deliveries'

  @Attr() id!: string
  @Attr() createdAt!: Date
  @Attr() amount!: number
  @Attr() totalCodesNumber!: number
  @Attr() distributedCodesNumber!: number
  @Attr() match!: boolean
  @Attr() processed!: boolean
  @Attr() distribution!: any
  @Attr() lastDownloadedAt!: string
  @Attr() codesFile!: any
  @Attr() expireAt!: string
  @Attr() activateAt!: string

  @BelongsTo() order!: Order
  @BelongsTo() delivery!: Delivery
}
