import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Customer, DotationOperation, CustomerOrder, Caretaker } from '@/models/index'

@Model()
export default class Dotation extends ApplicationRecord {
  static jsonapiType = 'dotations'

  @Attr() id!: string
  @Attr() amount!: number
  @Attr() remainingAmount!: number
  @Attr() expireAt!: string
  @Attr() usedAt!: string
  @Attr() createdAt!: string

  @BelongsTo() source!: Caretaker | CustomerOrder
  @BelongsTo() customer!: Customer
  @BelongsTo() dotationOperation!: DotationOperation
  @BelongsTo() customerOrder!: CustomerOrder
}
