import { Model, Attr, BelongsTo, HasOne } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Advertiser, Budget } from '@/models/index'

@Model()
export default class Caretaker extends ApplicationRecord {
  static jsonapiType = 'caretakers'

  @Attr() id!: string
  @Attr() name!: string
  @Attr() multiChoiceGiftCardBrandName!: string
  @Attr() logo!: string
  @Attr() createdAt!: string
  @Attr({ persist: false }) logoBlob!: any

  @BelongsTo() advertiser!: Advertiser
  @HasOne() budget!: Budget
}
