import { Model, Attr, BelongsTo, HasMany } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Manageable, Rate } from '@/models/index'

@Model()
export default class RateCard extends ApplicationRecord {
  static jsonapiType = 'rate_cards'

  @Attr() id!: string
  @Attr() name!: string
  @Attr() requirement!: string
  @Attr() default!: boolean
  @Attr() vatIncluded!: boolean

  @BelongsTo() manageable!: Manageable
  @HasMany() rates!: Rate[]

  static fromBuyers() {
    return this
      .includes('manageable')
      .where({ manageableType: 'Buyer' })
  }
}
