import { Model, SpraypaintBase, MiddlewareStack } from 'spraypaint'

import { Notification } from 'element-ui'
import { UserModule } from '@/store/modules/user'

@Model()
class ApplicationRecord extends SpraypaintBase {
  static baseUrl = process.env.VUE_APP_API_URL || 'http://localhost:80'
  static apiNamespace = '/admin'

  static generateAuthHeader(token: string) {
    return `Bearer ${token}`
  }
}

const middleware: any = new MiddlewareStack()

middleware.afterFilters.push(async (response: any, json: any) => {
  if (response.status === 401) {
    await UserModule.LogOut()

    Notification.warning({
      title: 'Token expired',
      message: 'Token expired, you need to login again',
      type: 'warning',
      duration: 5000
    })
  } else if (response.status >= 400) {
    for (const e of json.errors) {
      Notification.error({
        title: e.title,
        message: e.detail,
        type: 'error',
        duration: 5000
      })
    }
    // eslint-disable-next-line no-throw-literal
    throw ('abort')
  }
})

ApplicationRecord.middlewareStack = middleware

export default ApplicationRecord
