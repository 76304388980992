import { Model, Attr } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'

@Model()
export default class Location extends ApplicationRecord {
  static jsonapiType = 'locations'

  @Attr() id!: string
  @Attr() name!: string
  @Attr() zipcode!: string
  @Attr() line1!: string
  @Attr() line2!: string
  @Attr() city!: string
  @Attr() state!: string
  @Attr() longitude!: number
  @Attr() latitude!: number
}
