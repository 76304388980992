import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Order, Invoice, Manageable } from '@/models/index'

@Model()
export default class BankTransfer extends ApplicationRecord {
  static jsonapiType = 'bank_transfers'

  @Attr() id!: string
  @Attr() amount!: number
  @Attr() reference!: string
  @Attr() sendAt!: Date
  @Attr() createdAt!: Date
  @Attr() proofOfPayment!: string

  @BelongsTo() manageable!: Manageable
  @BelongsTo() invoice!: Invoice
  @BelongsTo() order!: Order

  @Attr({ persist: false }) proofOfPaymentBlob!: any
}
