import { Model, Attr, HasOne } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Location } from '@/models/index'

@Model()
export default class Wholesaler extends ApplicationRecord {
  static jsonapiType = 'wholesalers'

  @Attr() id!: string
  @Attr() name!: string
  @Attr() logo!: string
  @Attr() siren!: string
  @Attr() rcs!: string
  @Attr() intraVatNumber!: string
  @Attr() standardNumberFormat!: string
  @Attr() commissionNumberFormat!: string

  @HasOne() location!: Location
}
