import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Order, Sku } from '@/models/index'

@Model()
export default class CardDistribution extends ApplicationRecord {
  static jsonapiType = 'card_distributions'

  @Attr() id!: string
  @Attr() quantity!: number
  @Attr() amount!: number
  @Attr() amountCents!: number

  @BelongsTo() order!: Order
  @BelongsTo() sku!: Sku
}
