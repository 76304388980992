import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

/* Layout */
import Layout from '@/layout/index.vue'

/* Router modules */
// import componentsRouter from './modules/components'
// import chartsRouter from './modules/charts'
// import tableRouter from './modules/table'
// import nestedRouter from './modules/nested'

Vue.use(VueRouter)

/*
  Note: sub-menu only appear when children.length>=1
  Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
*/

/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['admin', 'editor']   will control the page roles (allow setting multiple roles)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    tagView: false               if false, the page will be hidden in tag-view (default is true)
    affix: true                  if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
    noTranslate: true             if true, title in TagView will be not translated
  }
*/

/**
  ConstantRoutes
  a base page that does not have permission requirements
  all roles can be accessed
*/
export const constantRoutes: RouteConfig[] = [
  {
    path: '/redirect',
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import(/* webpackChunkName: "redirect" */ '@/views/redirect/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
    meta: { hidden: true }
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/error-page/404.vue'),
    meta: { hidden: true }
  },
  {
    path: '/401',
    component: () => import(/* webpackChunkName: "401" */ '@/views/error-page/401.vue'),
    meta: { hidden: true }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard/index.vue'),
        name: 'Dashboard',
        meta: {
          title: 'dashboard',
          icon: 'dashboard',
          affix: true
        }
      }
    ]
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
*/
export const asyncRoutes: RouteConfig[] = [
  {
    path: '/market',
    component: Layout,
    meta: {
      title: 'market',
      icon: 'shopping'
    },
    children: [
      {
        path: 'users',
        component: () => import('@/views/market/users/users-table.vue'),
        name: 'Users',
        meta: {
          title: 'users',
          icon: 'user'
        }
      },
      {
        path: 'buyers',
        component: () => import('@/views/market/buyers/buyers-table.vue'),
        name: 'Buyers',
        meta: {
          title: 'buyers',
          icon: 'user'
        }
      },
      {
        path: 'wholesalers',
        component: () => import('@/views/market/wholesalers/wholesalers-table.vue'),
        name: 'Wholesalers',
        meta: {
          title: 'wholesalers',
          icon: 'user'
        }
      },
      {
        path: 'sellers',
        component: () => import('@/views/market/sellers/sellers-table.vue'),
        name: 'Sellers',
        meta: {
          title: 'sellers',
          icon: 'user'
        }
      },
      {
        path: 'caretakers',
        component: () => import('@/views/market/caretakers/caretakers-table.vue'),
        name: 'Caretakers',
        meta: {
          title: 'caretakers',
          icon: 'user'
        }
      },
      {
        path: 'caretakers/:id/edit',
        component: () => import('@/views/market/caretakers/caretakers-edit/index.vue'),
        name: 'CaretakerEdit',
        props: true,
        meta: {
          title: 'caretakerEdit',
          icon: 'user',
          hidden: true,
          noTranslate: true
        }
      },
      {
        path: 'caretakers/create',
        component: () => import('@/views/market/caretakers/caretakers-edit/index.vue'),
        name: 'CaretakerCreate',
        props: true,
        meta: {
          title: 'caretakerCreate',
          icon: 'user',
          hidden: true,
          noTranslate: true
        }
      },
      {
        path: 'orders',
        component: () => import('@/views/market/orders/orders.vue'),
        name: 'Orders',
        meta: {
          title: 'orders',
          icon: 'user'
        }
      },
      {
        path: 'orders/:id/edit',
        component: () => import('@/views/market/orders/orders-edit/index.vue'),
        name: 'OrderEdit',
        props: true,
        meta: {
          title: 'orderEdit',
          icon: 'user',
          hidden: true,
          noTranslate: true
        }
      },
      {
        path: 'invoices',
        component: () => import('@/views/market/invoices/invoices.vue'),
        name: 'Invoices',
        meta: {
          title: 'invoices',
          icon: 'user'
        }
      },
      {
        path: 'invoices/:id/edit',
        component: () => import('@/views/market/invoices/invoices-edit/index.vue'),
        name: 'InvoiceEdit',
        meta: {
          title: 'invoiceEdit',
          icon: 'user',
          hidden: true,
          noTranslate: true
        }
      },
      {
        path: 'gift-card-sellers',
        component: () => import('@/views/market/gift-card-sellers/gift-card-sellers-table.vue'),
        name: 'GiftCard Sellers',
        meta: {
          title: 'giftCardSellers',
          icon: 'user'
        }
      },
      {
        path: 'gift-cards',
        component: () => import('@/views/market/gift-cards/gift-cards-table.vue'),
        name: 'GiftCards',
        meta: {
          title: 'giftCards',
          icon: 'user'
        }
      },
      {
        path: 'gift-card/:id/edit',
        component: () => import('@/views/market/gift-cards/gift-card-edit/index.vue'),
        name: 'GiftCardEdit',
        meta: {
          title: 'giftCardEdit',
          icon: 'user',
          hidden: true
        }
      },
      {
        path: 'gift-card/create',
        component: () => import('@/views/market/gift-cards/gift-card-edit/index.vue'),
        name: 'GiftCardCreate',
        meta: {
          title: 'giftCardCreate',
          icon: 'user',
          hidden: true
        }
      },
      {
        path: 'rate-cards',
        component: () => import('@/views/market/rate-cards/rate-cards-table.vue'),
        name: 'Rate Cards',
        meta: {
          title: 'rateCards',
          icon: 'user'
        }
      },
      {
        path: 'rate-cards/:id/edit',
        component: () => import('@/views/market/rate-cards/rate-cards-edit/index.vue'),
        name: 'RateCardEdit',
        meta: {
          title: 'rateCardEdit',
          icon: 'user',
          hidden: true
        }
      },
      {
        path: 'rate-cards/create',
        component: () => import('@/views/market/rate-cards/rate-cards-edit/index.vue'),
        name: 'RateCardCreate',
        meta: {
          title: 'rateCardCreate',
          icon: 'user',
          hidden: true,
          tagView: false
        }
      },
      {
        path: 'purchase-infos',
        component: () => import('@/views/market/purchase-infos/purchase-infos-table.vue'),
        name: 'Purchase Infos',
        meta: {
          title: 'purchaseInfos',
          icon: 'user'
        }
      },
      {
        path: 'balances',
        component: () => import('@/views/market/balances/balances-table.vue'),
        name: 'balances',
        meta: {
          title: 'balances',
          icon: 'user'
        }
      }
    ]
  },
  {
    path: '/wallet',
    component: Layout,
    meta: {
      title: 'wallet',
      icon: 'wallet'
    },
    children: [
      {
        path: 'advertisers',
        component: () => import('@/views/wallet/advertisers/advertisers-table.vue'),
        name: 'Advertisers',
        meta: {
          title: 'advertisers',
          icon: 'user'
        }
      },
      {
        path: 'advertisers/create',
        component: () => import('@/views/wallet/advertisers/advertisers-edit/index.vue'),
        name: 'AdvertiserCreate',
        meta: {
          title: 'AdvertisersCreate',
          icon: 'user',
          hidden: true,
          tagView: false
        }
      },
      {
        path: 'advertisers/:id/edit',
        component: () => import('@/views/wallet/advertisers/advertisers-edit/index.vue'),
        name: 'AdvertiserEdit',
        meta: {
          title: 'AdvertisersEdit',
          icon: 'user',
          hidden: true
        }
      },
      {
        path: 'customer_orders',
        component: () => import('@/views/wallet/customer-orders/customer-orders-table.vue'),
        name: 'customerOrders',
        meta: {
          title: 'customerOrders',
          icon: 'user'
        }
      },
      {
        path: 'customer_orders/:id/edit',
        component: () => import('@/views/wallet/customer-orders/customer-orders-edit/index.vue'),
        name: 'CustomerOrdersEdit',
        meta: {
          title: 'CustomerOrdersEdit',
          icon: 'user',
          hidden: true,
          noTranslate: true
        }
      },
      {
        path: 'categories',
        component: () => import('@/views/wallet/categories/categories-table.vue'),
        name: 'Categories',
        meta: {
          title: 'categories',
          icon: 'user'
        }
      },
      {
        path: 'category/create',
        component: () => import('@/views/wallet/categories/categories-edit/index.vue'),
        name: 'CategoryCreate',
        meta: {
          title: 'categoryCreate',
          icon: 'user',
          hidden: true,
          tagView: false
        }
      },
      {
        path: 'category/:id/edit',
        component: () => import('@/views/wallet/categories/categories-edit/index.vue'),
        name: 'CategoryEdit',
        meta: {
          title: 'categoryEdit',
          icon: 'user',
          hidden: true
        }
      },
      {
        path: 'customers',
        component: () => import('@/views/wallet/customers/customers-table.vue'),
        name: 'Customers',
        meta: {
          title: 'customers',
          icon: 'user'
        }
      },
      {
        path: 'customers/create',
        component: () => import('@/views/wallet/customers/customers-edit/index.vue'),
        name: 'CustomerCreate',
        meta: {
          title: 'customerCreate',
          icon: 'user',
          hidden: true,
          tagView: false
        }
      },
      {
        path: 'customers/:id/edit',
        component: () => import('@/views/wallet/customers/customers-edit/index.vue'),
        name: 'CustomerEdit',
        meta: {
          title: 'customerEdit',
          icon: 'user',
          hidden: true
        }
      },
      {
        path: 'cards',
        component: () => import('@/views/wallet/cards/index.vue'),
        name: 'Cards',
        meta: {
          title: 'cards',
          icon: 'user'
        }
      },
    ]
  },
  {
    path: '*',
    redirect: '/404',
    meta: { hidden: true }
  }
]

const createRouter = () => new VueRouter({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher // reset router
}

export default router
