import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Budget } from '@/models/index'

@Model()
export default class BudgetMove extends ApplicationRecord {
  static jsonapiType = 'budget_moves'

  @Attr() id!: string
  @Attr() amount!: number
  @Attr() creditedAt!: string
  @Attr() sourceType!: string
  @Attr() createdAt!: string

  @BelongsTo() budget!: Budget
  @BelongsTo() source!: any
}
