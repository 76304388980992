import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Caretaker, BurnProgram } from '@/models/index'

@Model()
export default class DotationOperation extends ApplicationRecord {
  static jsonapiType = 'dotation_operations'

  @Attr() id!: string
  @Attr() donorName!: string
  @Attr() donorLogo!: string
  @Attr() identifier!: string
  @Attr() maximumAmount!: number
  @Attr() remainingAmount!: number
  @Attr() dotationDuration!: string
  @Attr() cseKind!: string
  @Attr() startAt!: string
  @Attr() endAt!: string
  @Attr() createdAt!: string

  @BelongsTo() caretaker!: Caretaker
  @BelongsTo() burnProgram!: BurnProgram
}
