import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { GiftCard, Buyer, Seller, Wholesaler, Rate } from '@/models/index'

@Model()
export default class PurchaseInfo extends ApplicationRecord {
  static jsonapiType = 'purchase_infos'

  @Attr() id!: string
  @Attr() paymentMode!: string
  @Attr() deferredAmountLimit!: number
  @Attr() deferredAmountUsage!: number
  @Attr() deferredDuration!: number
  @Attr() deferredDeadline!: string
  @Attr() payVat!: string
  @Attr() offset!: string
  @Attr() partialModeOffset!: string
  @Attr() vatPayback!: string
  @Attr() differedPartialOffsetValue!: number
  @Attr() fromSeller!: boolean
  @Attr() apiEnabled!: boolean

  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() buyer!: Buyer
  @BelongsTo() seller!: Seller
  @BelongsTo() wholesaler!: Wholesaler
  @BelongsTo() rate!: Rate

  public isPrepaid() {
    return this.paymentMode === 'prepaid'
  }

  public isDeferred() {
    return this.paymentMode === 'differed'
  }

  public inCommissionVatPayback() {
    return this.vatPayback === 'in_commission_vat_payback'
  }

  public isVatIrrelevant() {
    return this.payVat === 'vat_irrelevant'
  }

  public isVatNotPaid() {
    return this.payVat === 'vat_not_paid'
  }

  public isVatPaid() {
    return this.payVat === 'vat_paid'
  }

  public isPartialOffset() {
    return this.offset === 'partial_offset'
  }

  public isCompleteOffset() {
    return this.offset === 'complete_offset'
  }

  public isDifferedPartialOffset() {
    return this.partialModeOffset === 'differed_partial_offset'
  }
}
