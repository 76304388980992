import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Caretaker } from '@/models/index'

@Model()
export default class Program extends ApplicationRecord {
  static jsonapiType = 'programs'

  @Attr() id!: string
  @Attr() name!: string
  @Attr() description!: string
  @Attr() createdAt!: string
  @Attr() defaultAcceptEarnDiscounts!: boolean
  @Attr() earnDiscountsOnlyAfterFirstOrder!: boolean
  @Attr() logo!: string
  @Attr() splashScreenLogo!: string
  @Attr() splashScreenBackground!: string
  @Attr() splashScreenBackgroundColor!: string
  @Attr() giftEnabled!: boolean
  @Attr() fallbackUrl!: string
  @Attr() primaryColor!: string
  @Attr() secondaryColor!: string
  @Attr() balanceEnabled!: boolean
  @Attr() balanceName!: string
  @Attr() maxOrderAmount!: number
  @Attr() walleoLogoEnabled!: number
  @Attr() multiChoiceCardEnabled!: number

  @BelongsTo() caretaker!: Caretaker
}
