import { Attr, Model, BelongsTo, HasMany } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { GiftCard, Caretaker, GiftCardDiscount } from '@/models/index'

@Model()
export default class GiftCardRate extends ApplicationRecord {
  static jsonapiType = 'gift_card_rates'

  @Attr() rate!: number
  @Attr({ persist: false }) createdAt!: string

  @HasMany() discounts!: GiftCardDiscount[]
  @BelongsTo() caretaker!: Caretaker
  @BelongsTo() giftCard!: GiftCard
}
