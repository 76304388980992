import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Manageable } from '@/models/index'

@Model()
export default class User extends ApplicationRecord {
  static jsonapiType = 'users'

  @Attr() email!: string
  @Attr() firstname!: string
  @Attr() lastname!: string
  @Attr() password!: string
  @Attr() passwordConfirmation!: string
  @Attr() confirmedAt!: string
  @Attr() discardedAt!: string
  @Attr() ignoreEmails!: boolean
  @Attr() manageableType!: string

  @BelongsTo() manageable!: Manageable
}
