import { Model, Attr, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import Buyer from '@/models/Buyer'

@Model()
export default class Balance extends ApplicationRecord {
  static jsonapiType = 'balances'

  @Attr() id!: string
  @Attr() amount!: number

  @BelongsTo() buyer!: Buyer
}
