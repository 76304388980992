import { Model, Attr, BelongsTo, HasOne } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Advertiser, Location, Program } from '@/models/index'

@Model()
export default class Customer extends ApplicationRecord {
  static jsonapiType = 'customers'

  @Attr() email!: string
  @Attr() firstname!: string
  @Attr() lastname!: string
  @Attr() password!: string
  @Attr() passwordConfirmation!: string
  @Attr() confirmedAt!: string
  @Attr() discardedAt!: string
  @Attr() createdAt!: string
  @Attr() subdomain!: string
  @Attr() enrollmentToken!: string
  @Attr() phoneNumber!: string
  @Attr() locked!: boolean
  @Attr() trusted!: boolean
  @Attr() businessEmail!: string
  @Attr() cniegCode!: null | string
  @Attr() isRetired!: boolean
  @Attr() registrationStatus!: string

  @BelongsTo() advertiser!: Advertiser
  @BelongsTo() program!: Program
  @HasOne() location!: Location

  static findByEmail(email: string) {
    return this.where({ email: email })
  }

  public getColorByName() {
    let name = ''
    let hash = 0

    if (this.firstname && this.lastname) name = this.firstname + this.lastname

    if (name.length === 0) return hash
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash)
      hash = hash & hash
    }
    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 255
      color += ('00' + value.toString(16)).substr(-2)
    }
    return color
  }
}
