import { Attr, Model, BelongsTo } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { GiftCard, Program, GiftCardRate } from '@/models/index'

@Model()
export default class GiftCardDiscount extends ApplicationRecord {
  static jsonapiType = 'gift_card_discounts'

  @Attr() discount!: number
  @Attr({ persist: false }) createdAt!: string
  @Attr() discardedAt!: string

  @BelongsTo() program!: Program
  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() rate!: GiftCardRate
}
