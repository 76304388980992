import { Model, Attr, BelongsTo, HasOne } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { GiftCard, Sku, Customer, CustomerOrder, Delivery, PullRequest } from '@/models/index'
import request from '@/utils/request'
import { Notification } from 'element-ui'

@Model()
export default class Card extends ApplicationRecord {
  static jsonapiType = 'cards'

  @Attr() id!: string
  @Attr() amount!: number
  @Attr() remainingAmount!: number
  @Attr() pinCode!: string
  @Attr() code!: string
  @Attr() requestId!: string
  @Attr() createdAt!: string
  @Attr() activateAt!: string
  @Attr() expireAt!: string
  @Attr() technicalExpireAt!: string
  @Attr() distributedAt!: string
  @Attr() usageStatus!: string
  @Attr() discardedAt!: string

  @BelongsTo() giftCard!: GiftCard
  @BelongsTo() sku!: Sku
  @BelongsTo() customer!: Customer
  @BelongsTo() customerOrder!: CustomerOrder
  @BelongsTo() bulkDelivery!: Delivery
  @BelongsTo() delivery!: Delivery

  @HasOne() pullRequest!: PullRequest

  public async block() {
    const params = {
      data: {
        id: this.id,
        type: 'cards'
      }
    }

    try {
      const { data } = await request.put(`/admin/cards/${this.id}/block`, params)

      Notification({
        title: 'Card blocked',
        message: 'Card has been blocked',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public async revoke() {
    const params = {
      data: {
        id: this.id,
        type: 'cards'
      }
    }

    try {
      const { data } = await request.put(`/admin/cards/${this.id}/revoke`, params)

      Notification({
        title: 'Card revoked',
        message: 'Card has been revoked',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public async undistribute() {
    const params = {
      data: {
        id: this.id,
        type: 'cards'
      }
    }

    try {
      const { data } = await request.put(`/admin/cards/${this.id}/undistribute`, params)

      Notification({
        title: 'Card undistributed',
        message: 'Card has been undistributed',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }

  public async refresh() {
    const params = {
      data: {
        id: this.id,
        type: 'cards'
      }
    }

    try {
      const { data } = await request.put(`/admin/cards/${this.id}/refresh`, params)

      Notification({
        title: 'Card infos refreshed',
        message: 'Card infos has been refreshed',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          Notification({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      throw e
    }
  }
}
