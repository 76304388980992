import { Model, Attr, BelongsTo } from 'spraypaint'
import { Notification } from 'element-ui'
import request from '@/utils/request'

import ApplicationRecord from '@/models/ApplicationRecord'
import GiftCard from '@/models/GiftCard'

@Model()
export default class Sku extends ApplicationRecord {
  static jsonapiType = 'skus'

  @Attr() id!: string
  @Attr() discardedAt!: Date
  @Attr() faceValueFree!: boolean
  @Attr() faceValueMin!: number | undefined
  @Attr() faceValueMax!: number | undefined
  @Attr() faceValue!: number | null
  @Attr() code!: string
  @Attr() centsEnabled!: boolean

  @Attr() cardImage!: string
  @Attr({ persist: false }) cardImageBlob!: any

  @BelongsTo() giftCard!: GiftCard

  public isDiscarded() {
    return this.discardedAt !== undefined && this.discardedAt !== null
  }

  public async discard() {
    const params = {
      data: {
        id: this.id,
        type: 'skus'
      }
    }

    try {
      const { data } = await request.put(`/admin/skus/${this.id}/discard`, params)
      this.discardedAt = data.attributes.discardedAt

      Notification({
        title: 'Sku Discarded',
        message: 'Sku is Discard',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      Notification({
        title: 'Discard error',
        message: 'Unable to discard the sku',
        type: 'error',
        duration: 2000
      })
    }
  }

  public async undiscard() {
    const params = {
      data: {
        id: this.id,
        type: 'skus'
      }
    }

    try {
      const { data } = await request.put(`/admin/skus/${this.id}/undiscard`, params)
      this.discardedAt = data.attributes.discardedAt

      Notification({
        title: 'Sku Undiscarded',
        message: 'Sku is Undiscard',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      Notification({
        title: 'Discard error',
        message: 'Unable to undiscard the sku',
        type: 'error',
        duration: 2000
      })
    }
  }
}
