import { Model, Attr, HasOne } from 'spraypaint'

import ApplicationRecord from '@/models/ApplicationRecord'
import { Location } from '@/models/index'

@Model()
export default class Seller extends ApplicationRecord {
  static jsonapiType = 'sellers'

  @Attr() id!: string
  @Attr() name!: string
  @Attr() code!: string
  @Attr() apiClient!: string
  @Attr() paymentMode!: string
  @Attr() invoicePer!: string
  @Attr() offset!: string
  @Attr() partialModeOffset!: string
  @Attr() payVat!: string
  @Attr() vatPayback!: string
  @Attr() logo!: string
  @Attr() balance!: number
  @Attr() vat!: string
  @Attr() siret!: string

  @HasOne() location!: Location
}
